'use strict';

(function ($, TAMA, cssua) {
	var a = 'is-active';
	var f = 'is-fixed';

	var detailPageNav = function detailPageNav() {
		if ($('.products-detail-nav').length) {
			var $detailNav = $('.products-detail-nav');
			var $detailNavBtn = $('.products-detail-nav-btn');

			if (!cssua.ua.mobile) {
				$detailNav.hover(function (e) {
					$(e.currentTarget).addClass(a);
				}, function (e) {
					$(e.currentTarget).removeClass(a);
				});
				$detailNav.on('keydown', function (e) {
					if (e.key === 'Enter') {
						if (!$detailNav.hasClass(a)) {
							$detailNav.addClass(a);
							$detailNav.find('a[tabindex="-1"]').each(function (idx, ele) {
								$(ele).attr('tabindex', '0');
							});
						} else {
							$detailNav.removeClass(a);
							$detailNav.find('a[tabindex="0"]').each(function (idx, ele) {
								$(ele).attr('tabindex', '-1');
							});
						}
					}
				});
			} else {
				$detailNavBtn.on('click', function () {
					if (!$detailNav.hasClass(a)) {
						$detailNav.addClass(a);
					} else {
						$detailNav.removeClass(a);
					}
				});
			}
		}
	};
	var changeTab = function changeTab() {
		if ($('.js-tab-parent').length) {
			var $parent = $('.js-tab-parent');
			var $child = $('.js-tab-child');

			$child.eq(0).addClass(a);
			$parent.eq(0).addClass(a);

			$parent.each(function (idx, ele) {
				$(ele).on('click', function (e) {
					$child.removeClass(a);
					$parent.removeClass(a);
					$(e.currentTarget).addClass(a);
					$child.eq(idx).addClass(a);
				});
				$(ele).on('keydown', function (e) {
					if (e.key === 'Enter') {
						$child.removeClass(a);
						$parent.removeClass(a);
						$(e.currentTarget).addClass(a);
						$child.eq(idx).addClass(a);
					}
				});
			});
		}
	};
	var setShareLinks = function setShareLinks() {
		$('.js-share-item').each(function (idx, ele) {
			var url = encodeURIComponent(document.URL);
			var title = encodeURIComponent(document.title);
			var $this = $(ele);
			var href = $this.attr('href').replace(/#link/ig, url).replace(/#text/ig, title);

			$this.attr('href', href);
		});
	};

	detailPageNav();
	changeTab();
	setShareLinks();
})(window.jQuery, window.TAMA, window.cssua);