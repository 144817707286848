'use strict';

window.console || (window.console = {
	log: function log() {}
});
var TAMA = window.TAMA || null;
var cssua = window.cssua || null;

(function ($) {
	var siteName = 'tama';
	var a = 'is-active';
	var v = 'is-visible';
	var f = 'is-fixed';
	var slideSpeed = '400';

	TAMA = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test\\.' + siteName + '\\.connecty\\.com|\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function (e) {
				$(e.currentTarget).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
				if ($(e.currentTarget).hasClass(a)) {
					$(e.currentTarget).removeClass(a).attr('aria-expanded', false);
				} else {
					$(e.currentTarget).addClass(a).attr('aria-expanded', true);
				}
			});
			$switch.keydown(function (e) {
				if (e.key === 'Enter') {
					$(e.currentTarget).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
					if ($(e.currentTarget).hasClass(a)) {
						$(e.currentTarget).removeClass(a).attr('aria-expanded', false);
						$(e.currentTarget).next('.js-toggleBlock').find('[tabindex="-1"]').each(function (idx, ele) {
							$(ele).attr('tabindex', '0');
						});
					} else {
						$(e.currentTarget).addClass(a).attr('aria-expanded', true);
						$(e.currentTarget).next('.js-toggleBlock').find('[tabindex="0"]').each(function (idx, ele) {
							$(ele).attr('tabindex', '-1');
						});
					}
				}
			});
		},
		easeScroll: function easeScroll() {
			var scrollSpeed = 1200;

			var scrollToObject = function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (TAMA.va.window.width < TAMA.va.device.sp) {
						pos -= 65;
					} else if ($('.inc_world_header').length) {
						// worldヘッダーの場合
						pos -= 77;
					} else {
						pos -= 129;
					}
				} else {
					$('.header-search').focus();
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			};

			$('a[rel="scroll"]').on('click', function (e) {
				if (/#/.test(e.currentTarget.href)) {
					scrollToObject(e.currentTarget.href, scrollSpeed);
				}
				return false;
			});
			$('a[rel="scroll"]').on('keydown', function (e) {
				if (e.key === 'Enter') {
					if (/#/.test(e.currentTarget.href)) {
						scrollToObject(e.currentTarget.href, scrollSpeed);
					}
					// 商品詳細の左フロートCONTENTSナビ
					if ($(e.currentTarget).attr('href').indexOf('#products_detail_') !== -1) {
						if ($(e.currentTarget).attr('href') !== '#pagetop') {
							if ($($(e.currentTarget).attr('href')).find('[tabindex="0"]').length) {
								$($(e.currentTarget).attr('href')).find('[tabindex="0"]').eq(0).focus();
							} else {
								$($(e.currentTarget).attr('href')).find('a').eq(0).focus();
							}
							$('.products-detail-nav').removeClass(a);
						}
					}
					return false;
				}
			});
		},
		easeScrollHash: function easeScrollHash() {
			var hash = location.hash;
			if (hash) {
				$(window).on('load', function () {
					$('html, body').css('display', 'none');

					setTimeout(function () {
						$('html, body').css('display', 'block');

						var _hash = '#' + hash.split('#')[1];
						var pos = $(_hash).offset().top;

						if (TAMA.va.window.width < TAMA.va.device.sp) {
							pos -= 65;
						} else {
							pos -= 129;
						}
						$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					}, 100);
				});
			}
		},
		headerMenu: function headerMenu() {
			var $menu = $('.js-header-menu');
			var $menubtn = $('.js-header-menu-btn');
			var $menublock = $('.js-header-menu-block');
			var $searchBtn = $('.header-search');
			var $searchBlock = $('.js-header-search-block');
			var scrollTopNow = void 0;
			var flag = false;
			var shareFlag = false;
			var searchFlag = false;

			var removeMainContentsTabIndex = function removeMainContentsTabIndex() {
				// ヘッダー以外の要素のフォーカスを無効化する
				$('main').find('a').each(function (idx, ele) {
					if (!$(ele).is('[tabindex]')) {
						$(ele).attr('tabindex', '-2');
					}
				});
				$('main').find('[tabindex="0"]').each(function (idx, ele) {
					$(ele).attr('tabindex', '-2');
				});
				$('footer').find('a').each(function (idx, ele) {
					if (!$(ele).is('[tabindex]')) {
						$(ele).attr('tabindex', '-2');
					}
				});
				$('footer').find('[tabindex="0"]').each(function (idx, ele) {
					$(ele).attr('tabindex', '-2');
				});
			};
			var addMainContentsTabIndex = function addMainContentsTabIndex() {
				// ヘッダー以外の要素のフォーカスを無効化したものを元に戻す
				$('main').find('[tabindex="-2"]').each(function (idx, ele) {
					$(ele).attr('tabindex', '0');
				});
				$('footer').find('[tabindex="-2"]').each(function (idx, ele) {
					$(ele).attr('tabindex', '0');
				});
			};

			var fixedOnContainer = function fixedOnContainer() {
				scrollTopNow = $(window).scrollTop();
				$('.container').css({
					position: 'fixed',
					width: '100%',
					top: -1 * scrollTopNow,
					left: 0
				});
				$('body').addClass(f);
			};
			var fixedOffContainer = function fixedOffContainer() {
				$('.container').css({
					position: 'static'
				});
				$('body').removeClass(f);
				$(window).scrollTop(scrollTopNow);
			};

			var openMenu = function openMenu() {
				if (searchFlag) {
					closeSearchMenu();
				}
				if (shareFlag) {
					closeShareMenu();
				}

				fixedOnContainer();
				$menu.attr('aria-expanded', true);
				$menubtn.addClass(v);
				$menublock.addClass(v);

				$('.header-menuBlock-sp').find('[tabindex="-1"]').each(function (idx, ele) {
					$(ele).attr('tabindex', '0');
				});
				$('.header-menuBlock-sp .js-toggleBlock').find('[tabindex="0"]').each(function (idx, ele) {
					$(ele).attr('tabindex', '-1');
				});
				$('.header-menu-button-text').text('CLOSE');

				$('.js-header-menu-block').find('[tabindex="0"]').eq(0).focus();

				removeMainContentsTabIndex();
				flag = true;
			};
			var closeMenu = function closeMenu() {
				$('.header-menuBlock-sp').find('[tabindex="0"]').each(function (idx, ele) {
					$(ele).attr('tabindex', '-1');
				});

				// SPアコーディオンのtoggleリセット
				$('.js-toggle').removeClass(a);
				$('.js-toggleBlock').css('display', 'none');

				$('.header-menu-button-text').text('MENU');
				$menubtn.removeClass(v);
				$menublock.removeClass(v);
				$menu.attr('aria-expanded', false);
				fixedOffContainer();
				addMainContentsTabIndex();
				flag = false;
			};

			var openSearchMenu = function openSearchMenu() {
				if (flag) {
					closeMenu();
				}
				if (shareFlag) {
					closeShareMenu();
				}

				$('.header-menuBlock').find('.js-toggle').each(function (idx, ele) {
					// SPMENUアコーディオン不活性
					$(ele).attr('tabindex', '-1');
				});
				$('.header-nav').find('a').each(function (idx, ele) {
					// グローバルナビも一時的に無効
					$(ele).attr('tabindex', '-1');
				});

				fixedOnContainer();
				$searchBtn.addClass(v).attr('aria-expanded', true);
				$searchBlock.addClass(v);
				$('.header-searchBlock').find('[tabindex="-1"]').each(function (idx, ele) {
					$(ele).attr('tabindex', '0');
				});
				$('.header-search-text').text('CLOSE');
				$('.js-header-prodSearch-select').focus();

				removeMainContentsTabIndex();
				searchFlag = true;
			};
			var closeSearchMenu = function closeSearchMenu() {
				$('.header-searchBlock').find('[tabindex="0"]').each(function (idx, ele) {
					$(ele).attr('tabindex', '-1');
				});
				$('.header-nav').find('a[tabindex="-1"]').each(function (idx, ele) {
					$(ele).attr('tabindex', '0');
				});

				$('.header-search-text').text('SEARCH');
				$searchBtn.removeClass(v).attr('aria-expanded', false);
				$searchBlock.removeClass(v);
				fixedOffContainer();

				addMainContentsTabIndex();
				searchFlag = false;
			};

			var openShareMenu = function openShareMenu() {
				if (flag) {
					closeMenu();
				}
				if (searchFlag) {
					closeSearchMenu();
				}

				$('.header-menuBlock').find('.js-toggle').each(function (idx, ele) {
					$(ele).attr('tabindex', '-1');
				});
				$('.header-nav').find('a').each(function (idx, ele) {
					$(ele).attr('tabindex', '-1');
				});
				$('.header-searchBlock').find('[tabindex="0"]').each(function (idx, ele) {
					$(ele).attr('tabindex', '-1');
				});

				fixedOnContainer();
				$('.header-content-right-sp').addClass(v).attr('aria-expanded', true);
				$('.header-content-right-wrap').addClass(v);
				$('.header-content-right-wrap').find('[tabindex="-1"]').each(function (idx, ele) {
					$(ele).attr('tabindex', '0');
				});
				$('.header-content-right-sp span').text('CLOSE');

				removeMainContentsTabIndex();
				shareFlag = true;
			};
			var closeShareMenu = function closeShareMenu() {
				$('.header-nav').find('a[tabindex="-1"]').each(function (idx, ele) {
					$(ele).attr('tabindex', '0');
				});
				$('.header-content-right-wrap').find('[tabindex="0"]').each(function (idx, ele) {
					$(ele).attr('tabindex', '-1');
				});
				$('.header-content-right-wrap').removeClass(v);
				$('.header-content-right-sp').removeClass(v).attr('aria-expanded', false);
				$('.header-content-right-sp span').text('SHARE');
				fixedOffContainer();

				addMainContentsTabIndex();
				shareFlag = false;
			};

			// --------------------------------- ↑functions↑ ----------------------------
			// spハンバーガーメニュー
			$menu.on('click', function () {
				if (!flag) {
					openMenu();
				} else {
					closeMenu();
				}
			});
			$menu.on('keydown', function (e) {
				if (e.key === 'Enter') {
					if (!flag) {
						openMenu();
					} else {
						closeMenu();
					}
				}
			});

			// SEARCHボタン開閉
			$searchBtn.on('click', function () {
				if (!searchFlag) {
					openSearchMenu();
				} else {
					closeSearchMenu();
				}
			});
			$searchBtn.keydown(function (e) {
				if (e.key === 'Enter') {
					if (!searchFlag) {
						openSearchMenu();
					} else {
						closeSearchMenu();
					}
				}
			});

			// ナビアコーディオン キーボード操作
			$('.header-nav-in-head').keydown(function (e) {
				if (e.key === 'Enter') {
					e.preventDefault();
					if (!$(e.currentTarget).next('ul').hasClass(a)) {
						$('.header-nav-in-head').next('ul').each(function (idx, ele) {
							$(ele).removeClass(a);
						});
						$(e.currentTarget).next('ul').addClass(a);
						$(e.currentTarget).next('ul').find('[tabindex="-1"]').each(function (idx, ele) {
							$(ele).attr('tabindex', '0');
						});
					} else {
						$(e.currentTarget).next('ul').removeClass(a);
						$(e.currentTarget).next('ul').find('[tabindex="0"]').each(function (idx, ele) {
							$(ele).attr('tabindex', '-1');
						});
					}
				}
			});

			// SHAREメニュー
			$('.header-content-right-sp').on('click', function () {
				if (!shareFlag) {
					openShareMenu();
				} else {
					closeShareMenu();
				}
			});
			$('.header-content-right-sp').keydown(function (e) {
				if (e.key === 'Enter') {
					if (!shareFlag) {
						openShareMenu();
					} else {
						closeShareMenu();
					}
				}
			});

			// USAのみ Marchingニュースレター埋め込みフォーム
			if (TAMA.va.pathname.split('/')[1] === 'usa') {
				$('.idx-newsLetter-btn').on('click', function () {
					fixedOnContainer();
					$('.idx-newsLetter-block').addClass(v);
				});

				$('.idx-newsLetter-close').on('click', function () {
					$('.idx-newsLetter-block').removeClass(v);
					fixedOffContainer();
				});
			}

			// ヘッダーナビカレント
			var map = TAMA.va.pathname.split('/')[2];
			$('.header-nav-in-' + map).addClass(a);
		},
		incSlide: function incSlide() {
			if ($('.mod-series-slide').length) {
				// series部分
				$('.mod-series-slide').slick({
					slidesToShow: 4,
					responsive: [{
						breakpoint: 768,
						settings: {
							autoplay: true,
							slidesToShow: 1,
							slidesToScroll: 1,
							centerMode: true,
							centerPadding: '20%',
							prevArrow: '<i class="icon icon-arrow_left mod-slide-arrow mod-slide-arrow_left"></i>',
							nextArrow: '<i class="icon icon-arrow_right mod-slide-arrow mod-slide-arrow_right"></i>',
							loop: true,
							infinite: true
						}
					}]
				});
			}
			if ($('.support-menu-slide').length) {
				$('.support-menu-slide').slick({
					// autoplay: true,
					slidesToShow: 6,
					slidesToScroll: 1,
					prevArrow: '<i class="icon icon-arrow_left mod-slide-arrow mod-slide-arrow_left color_white"></i>',
					nextArrow: '<i class="icon icon-arrow_right mod-slide-arrow mod-slide-arrow_right color_white"></i>',
					loop: true,
					infinite: true,
					responsive: [{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							centerMode: true,
							centerPadding: '20%'
						}
					}]
				});
				$('.support-menu-item').matchHeight();
			}
		},
		searchCategoryList: function searchCategoryList() {
			if ($('.js-CategoryList').length) {
				var $spSearch = $('.js-sp-search-block');

				var toggleSearch = function toggleSearch() {
					if (!$('.js-sp-search-btn .icon-arrow_down').hasClass(a)) {
						$spSearch.stop().slideDown(200);
						$('.js-sp-search-btn .icon-arrow_down').addClass(a);
						$spSearch.find('select, input, a, [tabindex="-1"]').each(function (idx, ele) {
							$(ele).attr('tabindex', '0');
						});
						categoryListFlag = true;
					} else {
						$spSearch.stop().slideUp(200);
						$('.js-sp-search-btn .icon-arrow_down').removeClass(a);
						$spSearch.find('select, input, a, [tabindex="0"]').each(function (idx, ele) {
							$(ele).attr('tabindex', '-1');
						});
						categoryListFlag = false;
					}
				};

				$('.js-sp-search-btn').on('click', function () {
					toggleSearch();
				});
				$('.js-sp-search-btn').keydown(function (e) {
					if (e.key === 'Enter') {
						toggleSearch();
					}
				});

				// 初期表示がSPなら閉じておく
				if (TAMA.va.window.width < TAMA.va.device.sp) {
					$spSearch.hide();
					$('.js-sp-search-btn .icon-arrow_down').removeClass(a);
					$spSearch.find('select, input, a, [tabindex="0"]').each(function (idx, ele) {
						$(ele).attr('tabindex', '-1');
					});
				}
			}
		},
		modModal: function modModal() {
			if ($('.js-modal').length) {
				var scrollTopNow = void 0;
				var $modal = $('.js-modal');
				var $modalBtn = $('.js-modal-btn');
				var $modalOverlay = $('.js-modal-overlay');
				var $modalContent = $('.js-modal-content');

				var modalClose = function modalClose(_event) {
					$modal.removeClass(a);
					$('.container').css({
						position: 'static'
					});
					$('body').removeClass(f);
					$(window).scrollTop(scrollTopNow);
					_event.preventDefault();
				};
				var modalOpen = function modalOpen(_event) {
					var $this = $(_event.currentTarget);
					var modalImg = void 0;
					if ($this.find('.js-modal-img').length) {
						modalImg = $this.find('.js-modal-img').attr('src');
					} else if ($this.attr('href') !== '') {
						modalImg = $this.attr('href');
					} else if ($this.next('img').length) {
						modalImg = $this.next('img').attr('src');
					}
					$modalContent.html('<img src="' + modalImg + '"><i class="icon icon-cloase" tabindex="0"></i>');

					$modal.addClass(a);
					scrollTopNow = $(window).scrollTop();
					$('.container').css({
						position: 'fixed',
						width: '100%',
						top: -1 * scrollTopNow,
						left: 0
					});
					$('body').addClass(f);
					_event.preventDefault();

					$('.js-modal-content i').focus();
					$('.js-modal-content i').keydown(function (e) {
						if (e.key === 'Enter') {
							modalClose(e);
						}
					});
					$modalContent.on('click', $('.js-modal-content i'), function (e) {
						modalClose(e);
					});
					$modalOverlay.on('click', function (e) {
						modalClose(e);
					});
				};

				$modalBtn.on('click', function (e) {
					modalOpen(e);
				});
				$modalBtn.keydown(function (e) {
					if (e.key === 'Enter') {
						modalOpen(e);
					}
				});
			}
		},
		headerProdSearch: function headerProdSearch() {
			if ($('.mod-siteSearch-box_products').length) {
				var $searchBtn = $('.js-header-prodSearch');
				var $searchSelect = $('.js-header-prodSearch-select');
				var $searchInput = $('.js-header-prodSearch-input');

				$searchInput.change(function (e) {
					if (!$('.js-header-prodSearch-input').val()) {
						$searchBtn.attr('href', '' + $searchSelect.val());
					} else {
						$searchBtn.attr('href', $searchSelect.val() + '?cf_search_product_code=' + $(e.currentTarget).val() + '&keyword_1=' + $(e.currentTarget).val() + '&keyword_1_sub=' + $(e.currentTarget).val());
					}
				});
				$searchSelect.change(function (e) {
					if (!$('.js-header-prodSearch-input').val()) {
						$searchBtn.attr('href', '' + $(e.currentTarget).val());
					} else {
						$searchBtn.attr('href', $(e.currentTarget).val() + '?cf_search_product_code=' + $searchInput.val() + '&keyword_1=' + $searchInput.val() + '&keyword_1_sub=' + $searchInput.val());
					}
				});
			}
		},
		gdpr: function gdpr() {
			var $gdprBlock = $('#header-gdpr');
			var $gdprOk = $('#header-gdpr-in-btn');
			var $gdprClose = $('#header-gdpr-in-close');
			var cookieif = document.cookie.indexOf('gdprCookieDataTama');
			if (cookieif == -1) {
				$gdprBlock.addClass(a);
				$gdprBlock.find('a').each(function (idx, ele) {
					$(ele).attr('tabindex', '0');
				});
			}

			var setCookie = function setCookie() {
				var now = new Date();
				now.setDate(now.getDate() + 90);
				document.cookie = 'gdprCookieDataTama=ok;expires=' + now.toUTCString() + ';path=/;';
			};
			var closeModal = function closeModal() {
				$gdprBlock.removeClass(a);
				$gdprBlock.find('a').each(function (idx, ele) {
					$(ele).attr('tabindex', '-1');
				});
			};

			$gdprOk.on('click', function () {
				setCookie();closeModal();
			});
			$gdprOk.keydown(function (e) {
				if (e.key === 'Enter') {
					setCookie();closeModal();
				}
			});

			$gdprClose.on('click', function () {
				closeModal();
			});
			$gdprClose.keydown(function (e) {
				if (e.key === 'Enter') {
					closeModal();
				}
			});
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.headerMenu();
			_this.incSlide();
			_this.searchCategoryList();
			_this.easeScrollHash();
			_this.headerProdSearch();
			_this.gdpr();
			if (TAMA.va.pathname.indexOf('/news/detail/') !== -1) {
				_this.modModal();
			}
		}
	};

	$(function () {
		return TAMA.localDecision() ? TAMA.localLoading() : TAMA.loadDelayScript();
	});
})(window.jQuery);